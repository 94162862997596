<template>
    <div class="application-container">
        <Aside/>
        <main>
            <h1>Panel de administración</h1>
            <div class="info-card">
                <h3>Datos del Usuario</h3>
                <hr>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Código</p></div>
                    <div class="col-md-9"><b>{{session_user.id}}</b></div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Nombre</p></div>
                    <div class="col-md-9"><b>{{session_user.name + ' ' + session_user.last_name}}</b></div>
                </div>
                <div class="row mb-2">
                    <div class="col-md-3"><p>Patrocinador</p></div>
                    <div class="col-md-9">
                        <b v-if="session_user.sp_user_code">{{session_user.sp_user_name}}</b>
                        <b v-else>Comercializadora 3B</b>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <a style="margin: 0 auto; display: block; text-align: center;" href="#" @click="reload()">Recargar</a>
                    </div>
                </div>
            </div>
            <div class="insights">
                <!--=============END OF SALES========= -->
                <div @click="showCommissionsUser()" class="commissions">
                    <span class="material-icons-sharp">&#xef63;</span>
                    <div class="middle">
                        <div class="left">
                            <h3>Comisiones Totales</h3>
                            <div class="h3">
                                <label>${{this.formatMoney(session_wallet.current_money)[0]}}</label>
                                <label class="fs-4">,{{this.formatMoney(session_wallet.current_money)[1]}}</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!--=============END OF EXPENSES========= -->
                <div v-if="session_lines.loaded" @click="showLine('all')">
                    <span class="material-icons-sharp">group</span>
                    <div class="middle">
                        <div class="left">
                            <h3>Usuarios en Red</h3>
                            <h1>{{session_lines.total_users}} </h1>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div style="display: flex; justify-content: center; ">
                        <div class="lds-dual-ring"></div>
                    </div>
                </div>
                <!--=============END OF INCOME========= -->
            </div>
        </main>
        <Right/>
        <!------------MODAL COMS------------>
        <div class="modal fade" id="modal-commissions-user" tabindex="-1" aria-hidden="true" >
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title">Comisiones</h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="loading_commissions" style="display: flex; justify-content: center">
                            <div class="lds-facebook"><div></div><div></div><div></div></div>
                        </div>
                        <div :class="{'dnone':loading_commissions}">
                            <table class="table table-striped" id="table-commissions-user" style="border:none">
                                <thead>
                                    <tr>
                                        <th>Alianza</th>
                                        <th>Patrocinador</th>
                                        <th>Comisión</th>
                                        <th>Fecha</th>
                                    </tr>
                                </thead>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import Aside from '../components/Aside.vue';
import Right from '../components/Right.vue';


export default {
    name: 'Dashboard',
    components: {
        Aside,
        Right,
    },
    data: function(){
        return {
            loading_commissions: false,
        }
    },
    methods:{
        reload(){
            this.loadSessionUser({reload: true});
            this.loadSessionLines({reload: true});
            this.loadSessionWallet({reload: true});
        },
        showCommissionsUser(){
            this.loading_commissions = true;
            $('#modal-commissions-user').modal('show');
            this.userCommissions({id: this.session_user.id, order: 'DESC'}).then(res => {
                let new_array = [];
                res.data.forEach(element => {
                    let commission = `$${this.formatMoney(element.money)[0]},<label style="font-size: .8rem;">${this.formatMoney(element.money)[1]}</label>`;
                    let date = this.formatDate(element.created_at);
                    let json = {
                        sale_point_id: element.sale_point_id,
                        sale_point_name: element.sale_point_name,
                        client_user_id: element.client_user_id,
                        client_user_name: element.client_user_name,
                        commission: commission,
                        created_at: date
                    }
                    new_array.push(json);
                });
                this.loading_commissions = false;
                $("#table-commissions-user").DataTable({
                    order: [[3, 'desc']],
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data: new_array,
                    columns: [
                        {"data": "sale_point_name"},
                        {"data": "client_user_name"},
                        {"data": "commission"},
                        {"data": "created_at"},
                    ],
                });
            }).catch(err => console.log(err.response))
        },
        showLine(line) {
            let active_line = document.getElementById('active_line');
            active_line.innerHTML = 'Usuarios en Red'
            $('#modal-lines').modal('show');
            this.getUsersLine({
                user_id: this.session_user.id,
                line: line,
            }).then((res)=>{
                var data = [];
                res.data.forEach((element) => {
                    var date = this.formatDate(element.created_at);
                    let array = {
                        id: element.id,
                        name: element.name,
                        date
                    }
                    data.push(array);
                });
                $("#table-line").DataTable({
                    responsive: true,
                    autoWidth: false,
                    lengthChange: false,
                    info: false,
                    destroy: true,
                    data,
                    columns: [
                        {"data": "id"},
                        {"data": "name"},
                        {"data": "date"},
                    ]
                });
            });
        },
        formatMoney(number){
            let money = new Intl.NumberFormat('em-IN').format(number);
            let split = money.split(',');
            let decimals = split[1];
            if(!decimals) decimals = '00';
            if (window.innerWidth <= 838) return [Math.trunc(number), '']; 
            return [split[0], decimals];
        },
        formatDate(date){
            var fecha = new Date(date);
            var ano = fecha.getFullYear();
            var mes = fecha.getMonth()+1;
            var dia = fecha.getDate();
            if(mes < 10)  mes = "0" + mes
            if(dia < 10) dia = "0" + dia
            return ano+"/"+mes+"/"+dia;
        },
        ...mapActions("user", ["getUsersLine"]),
        ...mapActions("user", ["userCommissions"]),
        ...mapMutations(['loadSessionUser']),
        ...mapMutations(['loadSessionWallet']),
        ...mapMutations(['loadSessionLines']),
    },
    mounted() {
        const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 5000,
        })
        Toast.fire({
            html: `
            <a class="link" target="_blanck" href="https://3bvirtual.com">
                <div class="row" style="margin-right: 0; margin-left:0;">
                    <div class="col-4" 
                        style="margin-left: 0; margin-right: 0; padding-left: 0; padding-rigth: 0;">
                        <img src="./logo2.png"/ style="border-radius: .2rem;">
                    </div>
                    <div class="col-8">
                        <p class="mt-2" style="text-align: center;">¡3B en un solo click!</p>
                        <center><span class="link">3bvirtual.com</span></center>
                    </div>
                </div>
            </a>`,
        })
        $('.modal-backdrop').remove();
        this.loadSessionUser();
        let session_interval = setInterval(() => {
            if(this.session_user.id) {
                this.loadSessionWallet();
                return clearInterval(session_interval)}
        }, 100);
    },
    computed: {
        ...mapState(['session_user']),
        ...mapState(['session_wallet']),
        ...mapState(['session_lines']),
    }
}
</script>
<style>
.link{color: #007bff;}
</style>